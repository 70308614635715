import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'
import loadable from '@loadable/component'
import styled from 'styled-components'

import storageService from '../../services/storageService'

import GlobalStyle from '../css/globalStyle'

import { PaymentsProvider } from '../../context/paymentProvider'
import { ModalProvider } from '../../context/modalProvider'

import Header from '../nav/header'

import GtmScript from '../../templates/externalScripts/googlaTagManagerScript'
import LiveAgentScript from '../../templates/externalScripts/liveAgentScipt'
import LicenseScript from '../../templates/externalScripts/licenseScript'

const SeoContent = loadable(() => import('./seoContent'))
const Footer = loadable(() => import('./footer'))
const Maintenance = loadable(() => import('./maintenance'))
const ModalContainer = loadable(() => import('./modalContainer'))
const CookieMessage = loadable(() => import('./cookieMessage'))
const ToastContainer = loadable(() => import('./toastContainer'))

const Main = styled.main`
  background-color: #ffffff;
`

const Layout = ({ children }) => {
  const intl = useIntl()

  useEffect(() => {
    if (storageService.getLang() !== intl.locale) {
      storageService.setLang(intl.locale)
    }
  }, [intl.locale])

  return (
    <PaymentsProvider>
      <ModalProvider>
        <ToastContainer />
        <GlobalStyle />
        <Header locale={intl.locale} />
        <Main>{children}</Main>
        <SeoContent />
        <Footer locale={intl.locale} />
        <Maintenance locale={intl.locale} />
        <CookieMessage />
        <div id='modal-root' />
        <ModalContainer />

        <GtmScript />
        <LiveAgentScript />
        <LicenseScript />
      </ModalProvider>
    </PaymentsProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
