import React, { useState, useEffect } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import useModal from '../../hooks/useModal'
import Layout from '../../components/common/layout'
import Modal from '../../components/common/modal'
import storageService from '../../services/storageService'
import Withdraw from '../../components/payments/withdraw/withdraw'

import fake_sports_lp from '../../images/fake_sports_lp.png'

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  background: #1e1e1e url('${fake_sports_lp}') no-repeat top center;
  background-size: contain;
`

const WithdrawPage = () => {
  const intl = useIntl()
  const { closeModal } = useModal()
  const user = storageService.getUser()

  const [openModal, setOpenModal] = useState(false)

  const data = useStaticQuery(graphql`
    {
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    customSlugsApi: { values },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const sportSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'sports'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'sports')[0]
        .language_route
    : 'sports'

  useEffect(() => {
    setOpenModal(true)
  }, [setOpenModal])

  const handleCloseModal = (_) => {
    closeModal()
    navigate(`/${intl.locale}/${sportSlug}/`)
  }

  if (typeof window === 'undefined') return null

  if (!user) navigate(`/${intl.locale}/${sportSlug}/`)

  return (
    <Layout>
      <Wrapper>
        {openModal && user && (
          <Modal
            theme='payments'
            title={intl.formatMessage({ id: 'payments.withdraw.title' })}
            isOpen={true}
            onClose={handleCloseModal}
          >
            {user && <Withdraw onClose={handleCloseModal} />}
          </Modal>
        )}
      </Wrapper>
    </Layout>
  )
}

export default WithdrawPage
