import React, { useEffect, useRef, useState } from 'react'

import { EnvConfig } from '../../config/EnvConfig'

const LicenseScript = () => {
  const prevScrollY = useRef()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY) {
        const scriptElement = document.getElementById('license-script')
        if (!scriptElement) {
          const script = document.createElement('script')
          script.src = `${EnvConfig.GATSBY_LICENSE_SCRIPT}`
          script.defer = true
          script.id = 'license-script'
          script.addEventListener('load', () => setLoaded(true))
          document.body.appendChild(script)
        }
      }

      prevScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    window.addEventListener('mousemove', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('mousemove', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') return

    try {
      /* eslint-disable no-undef */
      xcm_71ba2d89_3cec_4117_835c_9273726cfe08.init()
    } catch (ex) {}
  }, [loaded])

  return <></>
}

export default LicenseScript
